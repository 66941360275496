import React from 'react';

function Cookie() {
  return (
    <section id="volanteos">
      <h2>You want to play? Let's play.</h2>
      {/* <p>Contenido de Volanteos.</p> */}
    </section>
  );
}

export default Cookie;
