import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

function CustomNavbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);

  const handleTitleClick = () => {
    setShowDropdown(false);
    navigate('/servicios');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container className="d-flex justify-content-between align-items-center">
        <LinkContainer to="/">
          <Navbar.Brand className="ms-3">
            <img
              src="/images/logo.png"
              height="60"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto me-3">
            {/* <LinkContainer to="/">
              <Nav.Link>Inicio</Nav.Link>
            </LinkContainer> */}
            {/* <LinkContainer to="/nosotros">
              <Nav.Link>Nosotros</Nav.Link>
            </LinkContainer> */}
            {/* <NavDropdown
              title={<span onClick={handleTitleClick}>Servicios</span>}
              id="basic-nav-dropdown"
              show={showDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <LinkContainer to="/servicios/transporteurbano">
                <NavDropdown.Item onClick={() => setShowDropdown(false)}>
                  Transporte Urbano
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/servicios/transporteturistico">
                <NavDropdown.Item onClick={() => setShowDropdown(false)}>
                  Transporte Turístico
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/servicios/vallasmoviles">
                <NavDropdown.Item onClick={() => setShowDropdown(false)}>
                  Vallas Móviles
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/servicios/volanteos">
                <NavDropdown.Item onClick={() => setShowDropdown(false)}>
                  Volanteos
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/servicios/perifoneos">
                <NavDropdown.Item onClick={() => setShowDropdown(false)}>
                  Perifoneos
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown> */}
            {/* <LinkContainer to="/portafolio">
              <Nav.Link>Portafolio</Nav.Link>
            </LinkContainer> */}
            {/* <LinkContainer to="/contacto">
              <Nav.Link>Contacto</Nav.Link>
            </LinkContainer> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
