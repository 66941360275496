import React from 'react';

function TransporteTuristico() {
  return (
    <section id="transporte-turistico">
      <h2>Transporte Turístico</h2>
      <p>Contenido de Transporte Turístico.</p>
    </section>
  );
}

export default TransporteTuristico;
