import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import Home from './components/Home';
import Nosotros from './components/Nosotros';
import Servicios from './components/Servicios';
import Portafolio from './components/Portafolio';
import Contacto from './components/Contacto';
import Perifoneos from './components/servicios/Perifoneos';
import TransporteTuristico from './components/servicios/TransporteTuristico';
import TransporteUrbano from './components/servicios/TransporteUrbano';
import VallasMoviles from './components/servicios/VallasMoviles';
import Volanteos from './components/servicios/Volanteos';
import Cookie from './components/Cookie';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <CustomNavbar />
      <div style={{ marginTop: '80px' }}> {/* Ajusta el margen para que el contenido no esté cubierto por la navbar */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/servicios/perifoneos" element={<Perifoneos />} />
          <Route path="/servicios/TransporteTuristico" element={<TransporteTuristico />} />
          <Route path="/servicios/transporteurbano" element={<TransporteUrbano />} />
          <Route path="/servicios/vallasmoviles" element={<VallasMoviles />} />
          <Route path="/servicios/volanteos" element={<Volanteos />} />
          <Route path="/portafolio" element={<Portafolio />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/sex" element={<Cookie />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
