import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer bg-dark text-center text-white">
      <div className="container">
        <img src="/images/logo.png" alt="Logo" className="footer-logo mb-3" />
        <p className="mb-0">© 2024 PublicityBus</p>
      </div>
    </footer>
  );
}

export default Footer;
