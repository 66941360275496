import React from 'react';

function TransporteUrbano() {
  return (
    <section id="transporte-urbano">
      <h2>Transporte Urbano</h2>
      <p>Contenido de Transporte Urbano.</p>
    </section>
  );
}

export default TransporteUrbano;
