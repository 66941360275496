import React from 'react';

function Nosotros() {
  return (
    <section id="nosotros">
      <h2>Nosotros</h2>
      <p>Historia de la empresa, misión, visión y valores, y equipo.</p>
    </section>
  );
}

export default Nosotros;
