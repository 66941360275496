import React from 'react';

function VallasMoviles() {
  return (
    <section id="vallas-moviles">
      <h2>Vallas Móviles</h2>
      <p>Contenido de Vallas Móviles.</p>
    </section>
  );
}

export default VallasMoviles;
