import React from 'react';

function Volanteos() {
  return (
    <section id="volanteos">
      <h2>Volanteos</h2>
      <p>Contenido de Volanteos.</p>
    </section>
  );
}

export default Volanteos;
