import React from 'react';

function Contacto() {
  return (
    <section id="contacto">
      <h2>Contacto</h2>
      <p>Formulario de contacto, información de contacto, redes sociales, y preguntas frecuentes.</p>
    </section>
  );
}

export default Contacto;
