import React from 'react';

function Perifoneos() {
  return (
    <section id="perifoneos">
      <h2>Perifoneos</h2>
      <p>Contenido de Perifoneos.</p>
    </section>
  );
}

export default Perifoneos;
