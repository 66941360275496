import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <h1 className="text-dark">Sitio en construcción</h1>
    </div>
  );
}

export default Home;
