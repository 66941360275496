import React from 'react';

function Portafolio() {
  return (
    <section id="portafolio">
      <h2>Portafolio</h2>
      <p>Galería de imágenes y videos de campañas publicitarias anteriores.</p>
    </section>
  );
}

export default Portafolio;
