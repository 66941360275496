import React from 'react';

function Servicios() {
  return (
    <section id="servicios">
      <h2>Servicios</h2>
      <p>Descripción de los servicios, tipos de publicidad, beneficios, y casos de éxito.</p>
    </section>
  );
}

export default Servicios;
